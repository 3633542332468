import React, { useRef, useState } from 'react';
import Modal from 'cccisd-modal';
import { Formik, Form, Field, CccisdInput, CccisdDatepicker, CccisdFieldWrapper } from 'cccisd-formik';
import SelectAssessmentForm from '../SelectAssessmentForm/index.js';
import axios from 'cccisd-axios';
import moment from 'moment';
import notification from 'cccisd-notification';
import PlusIcon from 'cccisd-icons/plus';
import IconCheckmark from 'cccisd-icons/checkmark';
// import _get from 'lodash/get';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;

const AddDeployment = props => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const modal = useRef();

    const applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    };

    const closeModal = () => {
        modal.current.close();
    };

    const onSubmit = async (values, { resetForm }) => {
        // console.log('on submit', { values, selectedRows, selectedPlan });

        const plan = AppDefs.app.assessments.find(p => p.index === selectedPlan);
        // console.log('on submit selected plan', { selectedPlan });
        // console.log('on submit plan', { plan });

        if (selectedPlan.length === 0) {
            notification({ message: ' You must select an assessment plan.', type: 'danger' });
        }

        // set the surveyList for the selected plan
        const msListObject = props.assignmentList.find(a => plan.assignmentHandle_MS === a.assignmentHandle);
        const msList = msListObject ? msListObject.surveyList.map(survey => survey.surveyHandle) : [];

        const hsListObject = props.assignmentList.find(a => plan.assignmentHandle_HS === a.assignmentHandle);
        const hsList = hsListObject ? hsListObject.surveyList.map(survey => survey.surveyHandle) : [];

        let okToClose = true;
        // await Promise.all(
        props.sites.forEach(async site => {
            let params = {
                label: values.planName,
                opensAt: applyTimezoneOffset(values.openDate),
                closesAt: applyTimezoneOffset(values.closeDate),
                isOpen: false,
                respondentsFromDcwId: null,
                roleHandle: 'respondent',
                type: 'anon',
                timepoint: '',
                description: null,
            };

            // determine handle  and assignment (ms or hs) based on group level
            if (selectedRows.includes(site.group.groupId)) {
                if (site.fields.middleRange) {
                    let deploymentHash = Math.random().toString(36).substring(2, 9);
                    params.hash = deploymentHash;
                    params.handle = site.group.groupId + '_ms_' + deploymentHash;
                    params.assignmentId = plan.assignmentId_MS;
                    params.settings = {
                        targetResponses: values.targetResponses,
                        groupId: site.group.groupId,
                        groupLabel: site.group.label,
                        closedMessage: 'This survey is no longer accepting responses.',
                        closedRoster: false,
                        completedMessage: 'You have already completed this survey. Thank you!',
                        completionMessage: 'You have completed the survey! Thank you for your response!',
                        options: msList,
                        otherAllowedRoles: ['uberadmin', 'questprojectadmin'],
                    };

                    // console.log('ms', { params });

                    const response = await axios.post(Boilerplate.route('api.assignmentDeployment.store'), params);

                    if (response.data.status === 'success') {
                        notification({
                            message: `Middle school assessment plan added for ${site.group.label}`,
                            type: 'success',
                            duration: 8000,
                        });
                    } else {
                        okToClose = false;
                        if (response.data.errors?.opensOn) {
                            notification({
                                message: `Open Date: ${response.data.errors?.opensOn}`,
                                type: 'danger',
                            });
                        }

                        if (response.data.errors?.closesOn) {
                            notification({
                                message: `Close Date: ${response.data.errors?.closesOn}`,
                                type: 'danger',
                            });
                        }
                    }
                }

                if (site.fields.highRange) {
                    let deploymentHash = Math.random().toString(36).substring(2, 9);
                    params.hash = deploymentHash;
                    params.handle = site.group.groupId + '_hs_' + deploymentHash;
                    params.assignmentId = plan.assignmentId_HS;
                    params.settings = {
                        targetResponses: values.targetResponses,
                        groupId: site.group.groupId,
                        groupLabel: site.group.label,
                        closedMessage: 'This survey is no longer accepting responses.',
                        closedRoster: false,
                        completedMessage: 'You have already completed this survey. Thank you!',
                        completionMessage: 'You have completed the survey! Thank you for your response!',
                        options: hsList,
                        otherAllowedRoles: ['uberadmin', 'questprojectadmin'],
                    };

                    // console.log('hs', { params });

                    const response = await axios.post(Boilerplate.route('api.assignmentDeployment.store'), params);

                    if (response.data.status === 'success') {
                        notification({
                            message: `High school assessment plan added for ${site.group.label}`,
                            type: 'success',
                            duration: 8000,
                        });
                    } else {
                        okToClose = false;
                        if (response.data.errors?.opensOn) {
                            notification({
                                message: `Open Date: ${response.data.errors?.opensOn}`,
                                type: 'danger',
                            });
                        }

                        if (response.data.errors?.closesOn) {
                            notification({
                                message: `Close Date: ${response.data.errors?.closesOn}`,
                                type: 'danger',
                            });
                        }
                    }
                }

                if (okToClose) {
                    closeModal();
                    resetForm();
                }
            }
        });
        // );
    };

    const validate = values => {
        try {
            let errors = {};
            if (!values.planName) {
                errors.planName = 'Plan Name is required';
            }

            if ((!selectedPlan && selectedPlan !== 0) || selectedPlan?.length === 0) {
                errors.assessmentPlan = 'Select an assessment for this plan.';
            }

            if (!values.openDate) {
                errors.openDate = 'Open Date is required.';
            }

            if (!values.closeDate) {
                errors.closeDate = 'Close Date is required.';
            }

            if (values.openDate === values.closeDate) {
                errors.openDate = 'Open Date and Close Date cannot be the same.';
            }

            if (selectedRows?.length === 0) {
                errors.sitesTable = 'Select one or more schools before continuing.';
            }

            return errors;
        } catch (e) {
            console.error(e);
        }
    };

    const handleRowSelection = rowIndex => {
        setSelectedRows(prevSelectedRows =>
            prevSelectedRows.includes(rowIndex)
                ? prevSelectedRows.filter(index => index !== rowIndex)
                : [...prevSelectedRows, rowIndex]
        );
    };

    const renderTable = () => {
        return (
            <table className={style.table} name="sitesTable">
                <thead>
                    <tr>
                        <th />
                        <th>School Name</th>
                        <th>Middle School Age</th>
                        <th>High School Age</th>
                    </tr>
                </thead>
                <tbody>
                    {props.sites.map((row, index) => (
                        <tr
                            key={row.group.groupId}
                            className={selectedRows.includes(row.group.groupId) ? style.selectedRow : ''}
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedRows.includes(row.group.groupId)}
                                    onChange={() => handleRowSelection(row.group.groupId)}
                                />
                            </td>
                            <td>{row.group.label}</td>
                            <td>
                                {row.fields.middleRange ? (
                                    <span>
                                        <IconCheckmark />
                                    </span>
                                ) : (
                                    ''
                                )}
                            </td>
                            <td>
                                {row.fields.highRange ? (
                                    <span>
                                        <IconCheckmark />
                                    </span>
                                ) : (
                                    ''
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const handlePlanSelection = planHandle => {
        setSelectedPlan(planHandle);
    };

    return (
        <div className={style.rightAlign}>
            <Modal
                ref={modal}
                trigger={
                    <button type="button" className="btn btn-primary">
                        <PlusIcon spaceRight /> Create YES Plan
                    </button>
                }
                title="Create YES Plan"
                size="large"
                headerStyle={{ backgroundColor: '#E4611F', color: '#fff' }}
                bodyStyle={{ backgroundColor: '#edf2f7' }}
                beforeClose={() => {
                    setCurrentPage(1);
                    setSelectedRows([]);
                    props.loadData();
                }}
            >
                <Formik
                    onSubmit={onSubmit}
                    validate={validate}
                    render={formProps => {
                        return (
                            <Form>
                                <div className={currentPage === 1 ? style.show : style.hidden}>
                                    <label className={`control-label ${style.required}`}>Plan Name:</label>
                                    <Field name="planName" component={CccisdInput} />
                                    {formProps.errors.planName && formProps.touched.planName && (
                                        <div className={style.error}>{formProps.errors.planName}</div>
                                    )}
                                    <div className={style.dates}>
                                        <div>
                                            <label className={`control-label ${style.required}`}>Open Date:</label>
                                            <Field name="openDate" component={CccisdDatepicker} showTimeSelect />
                                            {formProps.errors.openDate && formProps.touched.openDate && (
                                                <div className={style.error}>{formProps.errors.openDate}</div>
                                            )}
                                        </div>
                                        <div>
                                            <label className={`control-label ${style.required}`}>Close Date:</label>
                                            <Field name="closeDate" component={CccisdDatepicker} showTimeSelect />
                                            {formProps.errors.closeDate && formProps.touched.closeDate && (
                                                <div className={style.error}>{formProps.errors.closeDate}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <label className="control-label">
                                            Target Number of Complete Responses (optional):
                                        </label>
                                        <Field name="targetResponses" component={CccisdInput} type="number" />
                                        {formProps.errors.targetResponses && formProps.touched.targetResponses && (
                                            <div className={style.error}>{formProps.errors.targetResponses}</div>
                                        )}
                                    </div>

                                    <div className={style.tableContainer}>
                                        <label className={`control-label ${style.required}`}>Select School(s):</label>
                                        <Field
                                            name="sitesTable"
                                            render={({ field, form }) => (
                                                <CccisdFieldWrapper field={field} form={form}>
                                                    {renderTable()}
                                                </CccisdFieldWrapper>
                                            )}
                                        />
                                        {formProps.errors.sitesTable && formProps.touched.sitesTable && (
                                            <div className={style.error}>{formProps.errors.sitesTable}</div>
                                        )}
                                    </div>
                                </div>
                                <div className={currentPage === 2 ? style.show : style.hidden}>
                                    <Field
                                        name="assessmentPlan"
                                        render={({ field, form }) => (
                                            <CccisdFieldWrapper field={field} form={form}>
                                                <SelectAssessmentForm
                                                    onPlanSelection={handlePlanSelection}
                                                    form={formProps}
                                                />
                                            </CccisdFieldWrapper>
                                        )}
                                    />
                                    {formProps.errors.assessmentPlan && formProps.touched.assessmentPlan && (
                                        <div className={style.error}>{formProps.errors.assessmentPlan}</div>
                                    )}
                                </div>
                                <div className={style.dates}>
                                    <button
                                        className="btn btn-default"
                                        type="button"
                                        onClick={() => {
                                            if (currentPage === 1) {
                                                closeModal();
                                            } else {
                                                setCurrentPage(1);
                                            }
                                        }}
                                    >
                                        Back
                                    </button>
                                    <button
                                        className={
                                            currentPage === 1 ? 'btn btn-primary' : `${style.hidden} btn btn-primary`
                                        }
                                        type="button"
                                        onClick={() => {
                                            const values = formProps.values;

                                            formProps.setFieldTouched('openDate', true);
                                            formProps.setFieldTouched('closeDate', true);
                                            formProps.setFieldTouched('planName', true);
                                            formProps.setFieldTouched('sitesTable', true);

                                            if (
                                                values.closeDate &&
                                                values.openDate &&
                                                values.planName &&
                                                selectedRows.length > 0
                                            ) {
                                                setCurrentPage(2);
                                            } else {
                                                notification({
                                                    message: `Missing Required Fields`,
                                                    type: 'danger',
                                                });
                                            }
                                        }}
                                    >
                                        Choose Plan
                                    </button>
                                    <button
                                        className={
                                            currentPage === 2 ? 'btn btn-primary' : `${style.hidden} btn btn-primary`
                                        }
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                />
            </Modal>
        </div>
    );
};

export default AddDeployment;
